const cards = [
   {
      id: "img01",
      target: "",
      alt: "Cortina 2",
      title: "Cortina",
      description: "",
   },
   {
      id: "img02",
      target: "",
      alt: "Cortina de Varão",
      title: "Cortina de Varão",
      description: "",
   },
   {
      id: "img03",
      target: "",
      alt: "Cortina Trilho Suiço",
      title: "Cortina Trilho Suiço",
      description: "",
   },
   {
      id: "img04",
      target: "",
      alt: "Cortina Voal",
      title: "Cortina Voal",
      description: "",
   },
   {
      id: "img05",
      target: "",
      alt: "Persiana Doblou Vision",
      title: "Persiana Doblou Vision",
      description: "",
   },
   {
      id: "img06",
      target: "",
      alt: "Persiana Painel 2",
      title: "Persiana Painel II",
      description: "",
   },
   {
      id: "img07",
      target: "",
      alt: "Persiana Painel",
      title: "Persiana Painel",
      description: "",
   },
   {
      id: "img08",
      target: "",
      alt: "Persiana Romana 2",
      title: "Persiana Romana II",
      description: "",
   },
   {
      id: "img09",
      target: "",
      alt: "Persiana Romana",
      title: "Persiana Romana",
      description: "",
   },
   {
      id: "img10",
      target: "",
      alt: "Persiana para Sala Rolo",
      title: "Persiana para Sala Rolo",
      description: "",
   }
]

export { cards }